import React from "react"
import SegmentedButton from '../elements/buttons/SegmentedButton'
import { CheckBoxes, SingleCheckBoxProp } from '../elements/CheckBoxes'

export const utcHourIncToText = (hourInc: number) =>
    hourInc >= 0 ? `UTC+${hourInc}` : `UTC${hourInc}`

export const coordSystemNames = ['Lat/Long', 'UTM', 'SVY21']

export type CSVExportOptions = {
    selectedHourDiffFromUTC: number
    selectedCoordSystemOptionIdx: number
        // Does not matter when selectingCoordinate is false

    selectingCoordinate: boolean
    selectingDepth: boolean
    selectingAltitude: boolean
    selectingVelocity: boolean
    selectingHeading: boolean
    selectingPitch: boolean
    selectingRoll: boolean
    selectingCp?: boolean
    selectingUt?: boolean
    selectingContact?: boolean
    selectingProximity?: boolean
    selectingFieldGradient?: boolean
}

export type MaybeMissingCSVFieldsExistence = {
    cp: boolean
    ut: boolean
    contactAndFg: boolean
    proximity: boolean
} 

type CSVExportDialogProps = {
    diffFromUTCOptions: number[]
    selectedHourDiffFromUTC: number
    setSelectedHourDiffFromUTC: (idx: number) => void
    coordSystemOptions: string[]
    selectedCoordSystemOptionIdx: number
    setSelectedCoordSystemOptionIdx: (idx: number) => void
    selectingHeading: boolean
    setSelectingHeading: React.Dispatch<React.SetStateAction<boolean>>
    selectingPitch: boolean
    setSelectingPitch: React.Dispatch<React.SetStateAction<boolean>>
    selectingRoll: boolean
    setSelectingRoll: React.Dispatch<React.SetStateAction<boolean>>
    selectingCoordinate: boolean
    setSelectingCoordinate: React.Dispatch<React.SetStateAction<boolean>>
    selectingDepth: boolean
    setSelectingDepth: React.Dispatch<React.SetStateAction<boolean>>
    selectingAltitude: boolean
    setSelectingAltitude: React.Dispatch<React.SetStateAction<boolean>>
    selectingVelocity: boolean
    setSelectingVelocity: React.Dispatch<React.SetStateAction<boolean>>
    // Optional fields
    selectingCp: boolean
    setSelectingCp?: React.Dispatch<React.SetStateAction<boolean>>
    selectingUt: boolean
    setSelectingUt?: React.Dispatch<React.SetStateAction<boolean>>
    selectingContact: boolean
    setSelectingContact?: React.Dispatch<React.SetStateAction<boolean>>
    selectingProximity: boolean
    setSelectingProximity?: React.Dispatch<React.SetStateAction<boolean>>
    selectingFieldGradient: boolean
    setSelectingFieldGradient?: React.Dispatch<React.SetStateAction<boolean>>
}

const CSVExportDialog = ({
    diffFromUTCOptions,
    selectedHourDiffFromUTC,
    setSelectedHourDiffFromUTC,
    coordSystemOptions,
    selectedCoordSystemOptionIdx,
    setSelectedCoordSystemOptionIdx,
    selectingCoordinate,
    setSelectingCoordinate,
    selectingHeading,
    setSelectingHeading,
    selectingPitch,
    setSelectingPitch,
    selectingRoll,
    setSelectingRoll,
    selectingDepth,
    setSelectingDepth,
    selectingAltitude,
    setSelectingAltitude,
    selectingVelocity,
    setSelectingVelocity,
    selectingCp,
    setSelectingCp,
    selectingUt,
    setSelectingUt,
    selectingContact,
    setSelectingContact,
    selectingProximity,
    setSelectingProximity,
    selectingFieldGradient,
    setSelectingFieldGradient
}: CSVExportDialogProps) => {
    const timeZoneOptions = diffFromUTCOptions.map(utcHourIncToText)
    const indivCheckBoxesProp: SingleCheckBoxProp[] = [
        {
            checked: selectingCoordinate,
            onChange: setSelectingCoordinate,
            label: 'Coordinates'
        },
        {
            checked: selectingHeading,
            onChange: setSelectingHeading,
            label: 'Heading'
        },
        {
            checked: selectingPitch,
            onChange: setSelectingPitch,
            label: 'Pitch'
        },
        {
            checked: selectingRoll,
            onChange: setSelectingRoll,
            label: 'Roll'
        },
        {
            checked: selectingDepth,
            onChange: setSelectingDepth,
            label: 'Depth'
        },
        {
            checked: selectingAltitude,
            onChange: setSelectingAltitude,
            label: 'Altitude'
        },
        {
            checked: selectingVelocity,
            onChange: setSelectingVelocity,
            label: 'Velocity & direction'
        }
    ]
    // Add optional fields
    if (setSelectingCp !== undefined)
        indivCheckBoxesProp.push({
            checked: selectingCp,
            onChange: setSelectingCp,
            label: 'CP'
        })
    if (setSelectingUt !== undefined)
        indivCheckBoxesProp.push({
            checked: selectingUt,
            onChange: setSelectingUt,
            label: 'UT'
        })
    if (setSelectingContact !== undefined)
        indivCheckBoxesProp.push({
            checked: selectingContact,
            onChange: setSelectingContact,
            label: 'Contact'
        })
    if (setSelectingProximity !== undefined)
        indivCheckBoxesProp.push({
            checked: selectingProximity,
            onChange: setSelectingProximity,
            label: 'Proximity'
        })
    if (setSelectingFieldGradient !== undefined)
        indivCheckBoxesProp.push({
            checked: selectingFieldGradient,
            onChange: setSelectingFieldGradient,
            label: 'Field Gradient'
        })
    return (
        <div className='w-100 flex margin-t-md'>
            <div
                className='w-40 margin-l-sm'
            >
                <div className='h-50'>
                    <div
                        className='text-bold padding-r-sm'>
                        Time zones
                    </div>

                    <div
                        className='margin-t-sm w-100'>
                        <SegmentedButton
                            options={timeZoneOptions}
                            selected={diffFromUTCOptions.indexOf(
                                selectedHourDiffFromUTC
                            )}
                            onClick={idx => setSelectedHourDiffFromUTC(
                                diffFromUTCOptions[idx]
                            )}
                        />
                    </div>
                </div>
                {selectingCoordinate &&
                    <div className='h-50'>
                        <div
                            className='text-bold padding-r-sm'>
                            Coordinates system
                        </div>
                        <div
                            className='margin-t-sm w-100'>
                            <SegmentedButton
                                options={coordSystemOptions}
                                selected={selectedCoordSystemOptionIdx}
                                onClick={setSelectedCoordSystemOptionIdx}
                            />
                        </div>
                    </div>
                }
            </div>
            <div className='w-60'>
                <div
                    className='text-bold padding-r-sm'>
                    Date and time will be included by default.<br/>
                    Do you want to include any of these additional fields:
                </div>
                <div className='margin-l-lg'>
                    <CheckBoxes
                        indivCheckBoxesProp={indivCheckBoxesProp}
                        parentLabel='All of them'
                    />
                </div>
            </div>
        </div>
    )
}

export default CSVExportDialog