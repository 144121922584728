import {
    PDFDocument,
    StandardFonts,
    rgb,
    PDFFont,
    PDFImage,
    PDFPage,
    RotationTypes,
} from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'
import { saveAs } from 'file-saver'
import { toLatLon } from 'utm'
import { degToRad } from '../map/utils/CoordHelper/CoordUtils'
import {
    MarkerType,
    PDFReportImageType,
    timestampToDate,
    timestampToHMS,
    Hashtag,
} from '../../types'
import { SVY21 } from '../map/utils/CoordHelper/svy21'
import { processStrSeacp } from './DataUtils'

interface fontObject {
    helveticaBold: PDFFont
    helvetica: PDFFont
}

interface Coordinates {
    x: number
    y: number
}

enum Corner {
    topLeft = 0,
    topRight = 1,
    bottomRight = 2,
    bottomLeft = 3,
}

enum FontSizes {
    coverTitle = 20,
    title = 16,
    text = 10,
    hashtag = 8,
    pageNo = 8,
}

const createPDF = async (
    storage: string,
    markers: MarkerType[],
    usingSVY21: boolean,
    images: PDFReportImageType[],
    hashtagMap: Map<string, Hashtag[]>,
    clientName: string,
    objective: string,
    isPremium: boolean,
    clientLogoFile?: File
) => {
    let totalPages = markers.length

    const pdfDoc = await PDFDocument.create()
    //Load fonts
    pdfDoc.registerFontkit(fontkit)
    const helveticaBoldFont = await pdfDoc.embedFont(
        StandardFonts.HelveticaBold
    )
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    const fonts: fontObject = {
        helveticaBold: helveticaBoldFont,
        helvetica: helveticaFont,
    }
    //Load images
    let sonarImages: (PDFImage | null)[] = []
    let frontCamImages: (PDFImage | null)[] = []
    for (let i = 0; i < images.length; i++) {
        if (images[i].url) {
            const imgURL = images[i].url
            const imgBytes = await fetch(imgURL).then((res) =>
                res.arrayBuffer()
            )
            const image = await pdfDoc.embedJpg(imgBytes)
            if (images[i].img_type === 'sonar') {
                sonarImages.push(image)
            } else if (images[i].img_type === 'front_cam') {
                frontCamImages.push(image)
            }
        } else {
            if (images[i].img_type === 'sonar') {
                sonarImages.push(null)
            } else if (images[i].img_type === 'front_cam') {
                frontCamImages.push(null)
            }
        }
    }
    const warningUrl = '../../assets/images/warning.png'
    const warningBytes = await fetch(warningUrl).then((res) =>
        res.arrayBuffer()
    )
    const warningImage = await pdfDoc.embedPng(warningBytes)
    const logoUrl = '../../assets/images/logo_mmp.png'
    const logoBytes = await fetch(logoUrl).then((res) => res.arrayBuffer())
    const logoImage = await pdfDoc.embedPng(logoBytes)
    const watermarkUrl = '../../assets/images/watermark.png'
    const watermarkBytes = await fetch(watermarkUrl).then((res) =>
        res.arrayBuffer()
    )
    const watermarkImage = await pdfDoc.embedPng(watermarkBytes)
    //temporarily set client logo as mission portal logo
    let clientLogo = logoImage

    if (clientName !== '')
        createCoverPage(pdfDoc, fonts, clientName)

    if (clientLogoFile) {
        const clientLogoBytes = await clientLogoFile.arrayBuffer()
        if (clientLogoFile.type === 'image/jpeg')
            clientLogo = await pdfDoc.embedJpg(clientLogoBytes)
        else if (clientLogoFile.type === 'image/png')
            clientLogo = await pdfDoc.embedPng(clientLogoBytes)
    }
    let pageNumIncrement = 0
    if (objective !== '') {
        totalPages++
        clientLogoFile ? 
            createIntroPage(
                pdfDoc,
                fonts,
                logoImage,
                watermarkImage,
                objective,
                isPremium,
                1,
                totalPages,
                clientLogo
            ) :
            createIntroPage(
                pdfDoc,
                fonts,
                logoImage,
                watermarkImage,
                objective,
                isPremium,
                1,
                totalPages
            )
        pageNumIncrement = 1
    }
    for (let i = 0; i < markers.length; i++) {
        clientLogoFile
            ? createPage(
                  pdfDoc,
                  fonts,
                  markers[i],
                  usingSVY21,
                  storage,
                  hashtagMap,
                  frontCamImages,
                  sonarImages,
                  logoImage,
                  watermarkImage,
                  warningImage,
                  i + 1 + pageNumIncrement,
                  totalPages,
                  i,
                  isPremium,
                  clientLogo
              )
            : createPage(
                  pdfDoc,
                  fonts,
                  markers[i],
                  usingSVY21,
                  storage,
                  hashtagMap,
                  frontCamImages,
                  sonarImages,
                  logoImage,
                  watermarkImage,
                  warningImage,
                  i + 1 + pageNumIncrement,
                  totalPages,
                  i,
                  isPremium
              )
    }
    markers.forEach((marker) => {})

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save()
    // Trigger the browser to download the PDF document
    const blob = new Blob([pdfBytes], {
        type: 'application/octet-stream',
    })

    const fileName = generateFileName()
    saveAs(blob, fileName)
}

export default createPDF

const createPage = (
    pdfDoc: PDFDocument,
    fonts: fontObject,
    marker: MarkerType,
    usingSVY21: boolean,
    storage: string,
    hashtagMap: Map<string, Hashtag[]>,
    frontCamImages: (PDFImage | null)[],
    sonarImages: (PDFImage | null)[],
    logo: PDFImage,
    watermark: PDFImage,
    warningIcon: PDFImage,
    pageNo: number,
    totalPages: number,
    idx: number,
    isPremium: boolean,
    clientLogo?: PDFImage
) => {
    const page = pdfDoc.addPage()
    isPremium
        ? drawFooter(page, fonts, pageNo, totalPages)
        : drawFooter(page, fonts, pageNo, totalPages, logo, watermark)

    let coordArr: Coordinates[] = []

    clientLogo ? drawHeader(page, clientLogo) : null
    coordArr = drawTitleSection(page, marker, storage, fonts)
    coordArr = drawDataBox(
        page,
        marker,
        usingSVY21,
        hashtagMap,
        fonts,
        warningIcon,
        coordArr
    )
    drawImages(page, marker, frontCamImages, sonarImages, idx, fonts, coordArr)
}

const generateFileName = () => {
    const currentdate = new Date()
    const datetime =
        currentdate.getDate() +
        '-' +
        (currentdate.getMonth() + 1) +
        '-' +
        currentdate.getFullYear() +
        '_' +
        currentdate.getHours() +
        currentdate.getMinutes()
    return datetime + '_report.pdf'
}

const drawTitleSection = (
    page: PDFPage,
    marker: MarkerType,
    storage: string,
    fonts: fontObject
) => {
    const { width, height } = page.getSize()

    const date =
        marker.timestamp !== null
            ? 'Date: ' + timestampToDate(marker.timestamp)
            : 'Date: -'
    const time =
        marker.timestamp !== null
            ? 'Time: ' + timestampToHMS(marker.timestamp)
            : 'Time: -'

    page.drawText(marker.name, {
        x: 50,
        y: height - 6 * FontSizes.title,
        size: FontSizes.title,
        maxWidth: width - 100,
        font: fonts.helveticaBold,
        color: rgb(0, 0, 0),
    })

    page.drawText(storage, {
        x: 50,
        y: height - 6 * FontSizes.title - 15,
        size: FontSizes.text,
        font: fonts.helveticaBold,
        color: rgb(0, 0, 0),
    })

    page.drawText(date, {
        x:
            width -
            40 -
            fonts.helveticaBold.widthOfTextAtSize(date, FontSizes.text),
        y: height - 6 * FontSizes.title,
        size: FontSizes.text,
        font: fonts.helveticaBold,
        color: rgb(0, 0, 0),
    })

    page.drawText(time, {
        x:
            width -
            40 -
            fonts.helveticaBold.widthOfTextAtSize(time, FontSizes.text),
        y: height - 6 * FontSizes.title - 10,
        size: FontSizes.text,
        font: fonts.helveticaBold,
        color: rgb(0, 0, 0),
    })

    const coordArr = [
        { x: 50, y: height - 6 * FontSizes.title },
        { x: width - 100, y: height - 6 * FontSizes.title },
        { x: width - 100, y: height - 6 * FontSizes.title - 15 },
        { x: 50, y: height - 6 * FontSizes.title - 15 },
    ]

    return coordArr
}

const drawDataBox = (
    page: PDFPage,
    marker: MarkerType,
    usingSVY21: boolean,
    hashtagMap: Map<string, Hashtag[]>,
    fonts: fontObject,
    warningIcon: PDFImage,
    prevCoords: Coordinates[]
) => {
    const { width, height } = page.getSize()
    //Content to display
    const hashtagList = hashtagMap?.get(marker.bag_id)
    const rectStartX = prevCoords[Corner.bottomLeft].x
    const rectStartY = prevCoords[Corner.bottomLeft].y - 220
    const rectHeight = 20 * FontSizes.text

    const warningIconDims = warningIcon.scale(0.2)

    page.drawRectangle({
        x: rectStartX,
        y: rectStartY,
        width: width - 90,
        height: rectHeight,
        borderWidth: 0,
        color: rgb(0.8627, 0.8627, 0.8627),
    })

    const boxCoords = [
        {
            x: rectStartX,
            y: rectStartY + rectHeight,
        },
        {
            x: rectStartX + width - 90,
            y: rectStartY + rectHeight,
        },
        {
            x: rectStartX + width - 90,
            y: rectStartY - 170,
        },
        {
            x: rectStartX,
            y: rectStartY,
        },
    ]

    const offsetFrmCtr = 10

    drawDataColumn(page, marker, usingSVY21, fonts, boxCoords, offsetFrmCtr)
    drawCommentsSection(page, marker, fonts, boxCoords, offsetFrmCtr)
    hashtagList ? drawHashtags(page, hashtagList, fonts, boxCoords) : null
    if (marker.no_anode) {
        page.drawImage(warningIcon, {
            x: boxCoords[Corner.topRight].x - 25,
            y: boxCoords[Corner.topRight].y - 25,
            width: warningIconDims.width,
            height: warningIconDims.height,
        })
    }

    return boxCoords
}

const drawDataColumn = (
    page: PDFPage,
    marker: MarkerType,
    usingSVY21: boolean,
    fonts: fontObject,
    boxCoords: Coordinates[],
    offsetFrmCtr: number
) => {
    const boxMidWidth =
        (boxCoords[Corner.topRight].x + boxCoords[Corner.topLeft].x) / 2

    const latLon =
        marker.origin !== null && marker.position !== null
            ? toLatLon(
                  marker.origin.easting + marker.position.y,
                  marker.origin.northing + marker.position.x,
                  48,
                  'N',
                  undefined,
                  false
              )
            : { latitude: 0, longitude: 0 }
    const cv = new SVY21()
    const isValidSVY21 = cv.checkValidSVY21(latLon.latitude, latLon.longitude)
    let svy21Values: { N: number; E: number }
    if (usingSVY21)
        svy21Values = cv.computeSVY21(latLon.latitude, latLon.longitude)
    const latNorthingVal = usingSVY21 ?
        isValidSVY21 ? String(svy21Values!.N) + 'm' :           // If using SVY21 & valid SVY21
        'invalid' :                                             // If using SVY21 & invalid SVY21
        String(Math.round(latLon.latitude * 1000000) / 1000000) // If not using SVY21
    const latNorthing = marker.origin !== null ? latNorthingVal : '-'
    const lonEastingVal = usingSVY21 ? 
        isValidSVY21 ? String(svy21Values!.E) + 'm' :               // If using SVY21 & valid SVY21
        'invalid' :                                                 // If using SVY21 & invalid SVY21
        String(Math.round(latLon.longitude * 1000000) / 1000000)    // If not using SVY21
    const lonEasting = marker.origin !== null ? lonEastingVal : '-'
    const heading =
        marker.heading !== null ? Math.round(marker.heading) + '\xB0' : '-'
    const depth =
        marker.position !== null
            ? Math.round(marker.position.z * 100) / 100 + 'm'
            : '-'
    const altitude =
        marker.altitude !== null
            ? Math.round(marker.altitude * 100) / 100 + 'm'
            : '-'

    // Handle CP, UT, Str_seacp, PEC data which could be missing
    const additionalFieldValues = []
    const additionalFieldLabels = []
    const cp_probe = marker.cp ? marker.cp.toFixed(2) + 'V' : null
    if (cp_probe) {
        additionalFieldValues.push(cp_probe)
        additionalFieldLabels.push('CP')
    }
    const ut_probe = marker.ut ? marker.ut.toFixed(2) + 'mm' : null
    if (ut_probe) {
        additionalFieldValues.push(ut_probe)
        additionalFieldLabels.push('UT')
    }
    const str_seacp = marker.str_seacp ? processStrSeacp(marker.str_seacp) : null
    if (str_seacp) {
        const contact = Math.round(str_seacp.contact) + 'mV'
        const field_gradient = Math.round(str_seacp.field_gradient) + 'mV'
        const proximity = str_seacp.proximity !== null ?
            Math.round(str_seacp.proximity) + 'mV' : '-'
        additionalFieldValues.push(contact, field_gradient, proximity)
        additionalFieldLabels.push('CP Probe Contact', 'CP Probe Field Gradient', 'CP Probe Proximity')
    }
    if (marker.pec) {
        additionalFieldValues.push(
            `${marker.pec.thickness} mm`,
            `${marker.pec.calibration || '-'} mm`
        )
        additionalFieldLabels.push('PEC Thickness', 'PEC Calibration')
    }

    //Text
    if (usingSVY21) 
        page.drawText('Coordinates:', {
            x: boxCoords[Corner.topLeft].x + 20,
            y: boxCoords[Corner.topLeft].y - 25,
            size: FontSizes.text,
            font: fonts.helvetica,
            color: rgb(0, 0, 0),
        })
    const additionalYDecrement = usingSVY21 ? FontSizes.text : 0
    page.drawText(usingSVY21 ? 'Northing:' : 'Latitude:', {
        x: boxCoords[Corner.topLeft].x + 20,
        y: boxCoords[Corner.topLeft].y - 25 - additionalYDecrement,
        size: FontSizes.text,
        font: fonts.helvetica,
        color: rgb(0, 0, 0),
    })
    page.drawText(usingSVY21 ? 'Easting:' : 'Longitude:', {
        x: boxCoords[Corner.topLeft].x + 20,
        y: boxCoords[Corner.topLeft].y - 25 - FontSizes.text - additionalYDecrement,
        size: FontSizes.text,
        font: fonts.helvetica,
        color: rgb(0, 0, 0),
    })
    page.drawText('Heading:', {
        x: boxCoords[Corner.topLeft].x + 20,
        y: boxCoords[Corner.topLeft].y - 25 - 2 * FontSizes.text - additionalYDecrement,
        size: FontSizes.text,
        font: fonts.helvetica,
        color: rgb(0, 0, 0),
    })
    page.drawText('Depth:', {
        x: boxCoords[Corner.topLeft].x + 20,
        y: boxCoords[Corner.topLeft].y - 25 - 3 * FontSizes.text - additionalYDecrement,
        size: FontSizes.text,
        font: fonts.helvetica,
        color: rgb(0, 0, 0),
    })
    page.drawText('Altitude:', {
        x: boxCoords[Corner.topLeft].x + 20,
        y: boxCoords[Corner.topLeft].y - 25 - 4 * FontSizes.text - additionalYDecrement,
        size: FontSizes.text,
        font: fonts.helvetica,
        color: rgb(0, 0, 0),
    })
    for (let i = 0; i < additionalFieldLabels.length; i++)
        page.drawText(additionalFieldLabels[i] + ':', {
            x: boxCoords[Corner.topLeft].x + 20,
            y: boxCoords[Corner.topLeft].y - 25 - (5 + i) * FontSizes.text - additionalYDecrement,
            size: FontSizes.text,
            font: fonts.helvetica,
            color: rgb(0, 0, 0),
        })
    if (usingSVY21)
        page.drawText('SVY21', {
            x:
                boxMidWidth -
                fonts.helveticaBold.widthOfTextAtSize('SVY21', FontSizes.text) -
                offsetFrmCtr,
            y: boxCoords[Corner.topLeft].y - 25,
            size: FontSizes.text,
            font: fonts.helveticaBold,
            color: rgb(0, 0, 0),
        })

    page.drawText(latNorthing, {
        x:
            boxMidWidth -
            fonts.helveticaBold.widthOfTextAtSize(latNorthing, FontSizes.text) -
            offsetFrmCtr,
        y: boxCoords[Corner.topLeft].y - 25 - additionalYDecrement,
        size: FontSizes.text,
        font: fonts.helveticaBold,
        color: rgb(0, 0, 0),
    })

    page.drawText(lonEasting, {
        x:
            boxMidWidth -
            fonts.helveticaBold.widthOfTextAtSize(lonEasting, FontSizes.text) -
            offsetFrmCtr,
        y: boxCoords[Corner.topLeft].y - 25 - FontSizes.text - additionalYDecrement,
        size: FontSizes.text,
        font: fonts.helveticaBold,
        color: rgb(0, 0, 0),
    })

    page.drawText(heading, {
        x:
            boxMidWidth -
            fonts.helveticaBold.widthOfTextAtSize(heading, FontSizes.text) -
            offsetFrmCtr,
        y: boxCoords[Corner.topLeft].y - 25 - 2 * FontSizes.text - additionalYDecrement,
        size: FontSizes.text,
        font: fonts.helveticaBold,
        color: rgb(0, 0, 0),
    })

    page.drawText(depth, {
        x:
            boxMidWidth -
            fonts.helvetica.widthOfTextAtSize(depth, FontSizes.text) -
            offsetFrmCtr,
        y: boxCoords[Corner.topLeft].y - 25 - 3 * FontSizes.text - additionalYDecrement,
        size: FontSizes.text,
        font: fonts.helveticaBold,
        color: rgb(0, 0, 0),
    })

    page.drawText(altitude, {
        x:
            boxMidWidth -
            fonts.helvetica.widthOfTextAtSize(altitude, FontSizes.text) -
            offsetFrmCtr,
        y: boxCoords[Corner.topLeft].y - 25 - 4 * FontSizes.text - additionalYDecrement,
        size: FontSizes.text,
        font: fonts.helveticaBold,
        color: rgb(0, 0, 0),
    })
    for (let i = 0; i < additionalFieldValues.length; i++)
        page.drawText(additionalFieldValues[i], {
            x:
                boxMidWidth -
                fonts.helvetica.widthOfTextAtSize(
                    additionalFieldValues[i],
                    FontSizes.text
                ) -
                offsetFrmCtr,
            y: boxCoords[Corner.topLeft].y - 25 - (5 + i) * FontSizes.text - additionalYDecrement,
            size: FontSizes.text,
            font: fonts.helveticaBold,
            color: rgb(0, 0, 0),
        })
}

const drawCommentsSection = (
    page: PDFPage,
    marker: MarkerType,
    fonts: fontObject,
    boxCoords: Coordinates[],
    offsetFrmCtr: number
) => {
    const { width, height } = page.getSize()
    const boxMidWidth =
        (boxCoords[Corner.topRight].x + boxCoords[Corner.topLeft].x) / 2

    const commentWidth =
        width -
        (boxMidWidth + offsetFrmCtr) -
        offsetFrmCtr -
        (width - boxCoords[Corner.topRight].x)

    page.drawText('Comments: ', {
        x: boxMidWidth + offsetFrmCtr,
        y: boxCoords[Corner.topLeft].y - 25,
        size: FontSizes.text,
        font: fonts.helvetica,
        color: rgb(0, 0, 0),
    })

    if (marker.comment) {
        page.drawText(marker.comment, {
            x: boxMidWidth + offsetFrmCtr,
            y: boxCoords[Corner.topLeft].y - 25 - FontSizes.text,
            size: FontSizes.text,
            maxWidth: commentWidth,
            lineHeight: 10,
            font: fonts.helveticaBold,
            color: rgb(0, 0, 0),
        })
    } else {
        page.drawText('-', {
            x: boxMidWidth + offsetFrmCtr,
            y: boxCoords[Corner.topLeft].y - 25 - FontSizes.text,
            size: FontSizes.text,
            lineHeight: 10,
            font: fonts.helveticaBold,
            color: rgb(0, 0, 0),
        })
    }
}

const drawHashtags = (
    page: PDFPage,
    hashtagList: Hashtag[],
    fonts: fontObject,
    boxCoords: Coordinates[]
) => {
    const paddingY = 5
    const paddingX = 10
    let rectCoord = {
        x: boxCoords[Corner.bottomLeft].x + 20,
        y: boxCoords[Corner.bottomLeft].y + 15,
    }
    hashtagList.forEach((hashtagText) => {
        let str = ''
        const hashtag = str.concat('#', hashtagText.content)
        const strLength = fonts.helvetica.widthOfTextAtSize(
            hashtag,
            FontSizes.hashtag
        )
        // page.drawRectangle({
        //   x: rectCoord.x,
        //   y: rectCoord.y,
        //   width: strLength + 2 * paddingX,
        //   height: 2 * paddingY + FontSizes.hashtag,
        //   borderWidth: 1.0,
        //   borderColor: rgb(0, 0, 0),
        // })
        //Left border
        page.drawLine({
            start: { x: rectCoord.x, y: rectCoord.y + 1 },
            end: {
                x: rectCoord.x,
                y: rectCoord.y + 2 * paddingY + FontSizes.hashtag - 1,
            },
            thickness: 1,
            color: rgb(0, 0, 0),
        })
        //Bottom border
        page.drawLine({
            start: { x: rectCoord.x + 1, y: rectCoord.y },
            end: {
                x: rectCoord.x + strLength + 2 * paddingX - 1,
                y: rectCoord.y,
            },
            thickness: 1,
            color: rgb(0, 0, 0),
        })
        //Right border
        page.drawLine({
            start: {
                x: rectCoord.x + strLength + 2 * paddingX,
                y: rectCoord.y + 1,
            },
            end: {
                x: rectCoord.x + strLength + 2 * paddingX,
                y: rectCoord.y + 2 * paddingY + FontSizes.hashtag - 1,
            },
            thickness: 1,
            color: rgb(0, 0, 0),
        })
        //Top border
        page.drawLine({
            start: {
                x: rectCoord.x + 1,
                y: rectCoord.y + 2 * paddingY + FontSizes.hashtag,
            },
            end: {
                x: rectCoord.x + strLength + 2 * paddingX - 1,
                y: rectCoord.y + 2 * paddingY + FontSizes.hashtag,
            },
            thickness: 1,
            color: rgb(0, 0, 0),
        })
        //Bottom left curved corner
        page.drawEllipse({
            x: rectCoord.x + 2,
            y: rectCoord.y + 2,
            xScale: 2,
            yScale: 2,
            opacity: 0,
            rotate: {
                type: RotationTypes.Degrees,
                angle: -5,
            },
            borderColor: rgb(0, 0, 0),
            borderOpacity: 1,
            borderWidth: 1,
            borderDashArray: [(2 * Math.PI * 2) / 4, (6 * Math.PI * 2) / 4],
        })
        //Top left curved corner
        page.drawEllipse({
            x: rectCoord.x + 2,
            y: rectCoord.y - 2 + (2 * paddingY + FontSizes.hashtag),
            xScale: 2,
            yScale: 2,
            opacity: 0,
            rotate: {
                type: RotationTypes.Degrees,
                angle: -95,
            },
            borderColor: rgb(0, 0, 0),
            borderOpacity: 1,
            borderWidth: 1,
            borderDashArray: [(2 * Math.PI * 2) / 4, (6 * Math.PI * 2) / 4],
        })
        //Bottom right curved corner
        page.drawEllipse({
            x: rectCoord.x - 2 + (strLength + 2 * paddingX),
            y: rectCoord.y + 2,
            xScale: 2,
            yScale: 2,
            opacity: 0,
            rotate: {
                type: RotationTypes.Degrees,
                angle: 95,
            },
            borderColor: rgb(0, 0, 0),
            borderOpacity: 1,
            borderWidth: 1,
            borderDashArray: [(2 * Math.PI * 2) / 4, (6 * Math.PI * 2) / 4],
        })
        //Top right curved corner
        page.drawEllipse({
            x: rectCoord.x - 2 + (strLength + 2 * paddingX),
            y: rectCoord.y - 2 + (2 * paddingY + FontSizes.hashtag),
            xScale: 2,
            yScale: 2,
            opacity: 0,
            rotate: {
                type: RotationTypes.Degrees,
                angle: 185,
            },
            borderColor: rgb(0, 0, 0),
            borderOpacity: 1,
            borderWidth: 1,
            borderDashArray: [(2 * Math.PI * 2) / 4, (6 * Math.PI * 2) / 4],
        })
        page.drawText(hashtag, {
            x: rectCoord.x + paddingX,
            y: rectCoord.y + paddingY + 1,
            size: FontSizes.hashtag,
            font: fonts.helvetica,
            color: rgb(0, 0, 0),
        })
        rectCoord = {
            x: rectCoord.x + strLength + 2 * paddingX + 10,
            y: rectCoord.y,
        }
    })
}

const drawImages = (
    page: PDFPage,
    marker: MarkerType,
    frontCamImages: (PDFImage | null)[],
    sonarImages: (PDFImage | null)[],
    idx: number,
    fonts: fontObject,
    boxCoords: Coordinates[]
) => {
    const { width, height } = page.getSize()

    //Images
    let frontCamDims = {
        width: 0,
        height: 0,
    }
    let sonarCamDims = {
        width: 0,
        height: 0,
    }
    let frontCamBottomLeft = {
        x: 0,
        y: 0,
    }
    if (frontCamImages[idx] !== null) {
        const scale = (frontCamImages[idx] as PDFImage).width / 960
        frontCamDims = (frontCamImages[idx] as PDFImage).scale(0.35 / scale)
        page.drawImage(frontCamImages[idx] as PDFImage, {
            x: width / 2 - frontCamDims.width / 2,
            y: boxCoords[Corner.bottomLeft].y - 20 - frontCamDims.height,
            width: frontCamDims.width,
            height: frontCamDims.height,
        })
        page.drawText('Front Camera', {
            x: width / 2 - frontCamDims.width / 2,
            y:
                boxCoords[Corner.bottomLeft].y -
                20 -
                frontCamDims.height -
                FontSizes.text,
            size: FontSizes.text,
            font: fonts.helveticaBold,
            color: rgb(0, 0, 0),
        })
    } else {
        frontCamDims = {
            width: 336,
            height: 189,
        }
        page.drawRectangle({
            x: width / 2 - frontCamDims.width / 2,
            y: boxCoords[Corner.bottomLeft].y - 20 - frontCamDims.height,
            width: frontCamDims.width,
            height: frontCamDims.height,
            borderColor: rgb(0, 0, 0),
            borderWidth: 1.0,
        })
        page.drawText('No front camera image detected', {
            x: width / 2 - frontCamDims.width / 4 + 20,
            y:
                boxCoords[Corner.bottomLeft].y -
                20 -
                frontCamDims.height +
                frontCamDims.height / 2,
            size: FontSizes.text,
            font: fonts.helvetica,
            color: rgb(0, 0, 0),
        })
    }
    frontCamBottomLeft = {
        x: width / 2 - frontCamDims.width / 2,
        y: boxCoords[Corner.bottomLeft].y - 20 - frontCamDims.height,
    }

    if (sonarImages[idx] !== null) {
        const sonarHeight = (sonarImages[idx] as PDFImage).height
        const sonarWidth = (sonarImages[idx] as PDFImage).width
        //Dealing with landscape & portrait aspect ratio sonar images separately
        if (sonarWidth > sonarHeight) {
            const scaleY = sonarImages[idx]?.height
                ? frontCamDims.height / (sonarImages[idx] as PDFImage).height
                : 1
            const scaleX = sonarImages[idx]?.width
                ? frontCamDims.width / (sonarImages[idx] as PDFImage).width
                : 1
            const scaleFactor = (scaleX + scaleY) / 2
            sonarCamDims = frontCamDims
            page.drawImage(sonarImages[idx] as PDFImage, {
                x: width / 2 - frontCamDims.width / 2,
                y: frontCamBottomLeft.y - sonarCamDims.height - 30,
                width: sonarCamDims.width,
                height: sonarCamDims.height,
            })
            drawSonarGrid(
                page,
                marker,
                {
                    x: width / 2 - frontCamDims.width / 2,
                    y: frontCamBottomLeft.y - sonarCamDims.height - 30,
                },
                fonts,
                scaleX,
                scaleY,
                scaleFactor
            )
            page.drawText('Sonar', {
                x: width / 2 - frontCamDims.width / 2,
                y:
                    frontCamBottomLeft.y -
                    sonarCamDims.height -
                    30 -
                    FontSizes.text,
                size: FontSizes.text,
                font: fonts.helveticaBold,
                color: rgb(0, 0, 0),
            })
        } else {
            sonarCamDims = (sonarImages[idx] as PDFImage).scale(0.35)
            page.drawImage(sonarImages[idx] as PDFImage, {
                x: width / 2 - sonarCamDims.width / 2,
                y: frontCamBottomLeft.y - sonarCamDims.height - 30,
                width: sonarCamDims.width,
                height: sonarCamDims.height,
            })
            drawSonarGrid(
                page,
                marker,
                {
                    x: width / 2 - sonarCamDims.width / 2,
                    y: frontCamBottomLeft.y - sonarCamDims.height - 30,
                },
                fonts,
                0.35,
                0.35,
                0.35
            )
            page.drawText('Sonar', {
                x:
                    width / 2 -
                    fonts.helveticaBold.widthOfTextAtSize(
                        'Sonar',
                        FontSizes.text
                    ) /
                        2,
                y:
                    frontCamBottomLeft.y -
                    sonarCamDims.height -
                    30 -
                    FontSizes.text,
                size: FontSizes.text,
                font: fonts.helveticaBold,
                color: rgb(0, 0, 0),
            })
        }
    } else {
        sonarCamDims = {
            width: 336,
            height: 189,
        }
        page.drawRectangle({
            x: width / 2 - sonarCamDims.width / 2,
            y: frontCamBottomLeft.y - sonarCamDims.height - 30,
            width: sonarCamDims.width,
            height: sonarCamDims.height,
            borderColor: rgb(0, 0, 0),
            borderWidth: 1.0,
        })
        page.drawText('No sonar camera image detected', {
            x: width / 2 - sonarCamDims.width / 4 + 20,
            y:
                frontCamBottomLeft.y -
                sonarCamDims.height -
                30 +
                sonarCamDims.height / 2,
            size: FontSizes.text,
            font: fonts.helvetica,
            color: rgb(0, 0, 0),
        })
    }
}

const drawSonarGrid = (
    page: PDFPage,
    marker: MarkerType,
    imgStartingCoord: Coordinates,
    fonts: fontObject,
    scaleX: number,
    scaleY: number,
    scaleFactor: number
) => {
    const width = marker.sonar_info.width * scaleX
    const height = marker.sonar_info.height * scaleY

    const bottomLeftCoord = imgStartingCoord
    const bottomRightCoord = {
        x: imgStartingCoord.x + width,
        y: imgStartingCoord.y,
    }
    const bottomMiddleCoord = {
        x: (bottomLeftCoord.x + bottomRightCoord.x) / 2,
        y: imgStartingCoord.y,
    }

    const start_y =
        imgStartingCoord.y +
        Math.tan(degToRad(90 - marker.sonar_info.fov_max)) * (width / 2)

    page.drawLine({
        start: { x: imgStartingCoord.x, y: start_y },
        end: bottomMiddleCoord,
        thickness: 1,
        color: rgb(1, 1, 0),
        opacity: 1,
    })
    page.drawLine({
        start: bottomMiddleCoord,
        end: { x: bottomRightCoord.x, y: start_y },
        thickness: 1,
        color: rgb(1, 1, 0),
        opacity: 1,
    })

    const num_of_lines = 5
    const n = marker.sonar_info.max_range / num_of_lines
    const d = (n / marker.sonar_info.range_resol) * scaleFactor
    const text_offset = width < height ? 8 : 0
    for (let i = 1; i <= num_of_lines; i++) {
        const xCoord =
            bottomMiddleCoord.x -
            i * d * Math.sin(degToRad(marker.sonar_info.fov_max))
        const yCoord =
            bottomMiddleCoord.y +
            i * d * Math.cos(degToRad(marker.sonar_info.fov_max))

        const arcRadius = i * d
        const arcLength = degToRad(2 * marker.sonar_info.fov_max) * arcRadius
        const circumference = 2 * Math.PI * arcRadius

        page.drawText(String(Math.round(i * n * 10) / 10), {
            x: xCoord + 10,
            y: yCoord + text_offset,
            size: 8,
            font: fonts.helveticaBold,
            color: rgb(1, 1, 0),
        })

        page.drawEllipse({
            x: bottomMiddleCoord.x,
            y: bottomMiddleCoord.y,
            xScale: i * d,
            yScale: i * d,
            opacity: 0,
            rotate: {
                type: RotationTypes.Degrees,
                angle: 180 + (90 - marker.sonar_info.fov_max),
            },
            borderColor: rgb(1, 1, 0),
            borderOpacity: 1,
            borderWidth: 1,
            borderDashArray: [arcLength, circumference - arcLength],
        })
    }
}

const drawHeader = (page: PDFPage, logo: PDFImage) => {
    const { width, height } = page.getSize()
    const maxLogoHeight = 45
    const scale = maxLogoHeight / logo.height

    const logoDims = logo.scale(scale)
    page.drawImage(logo, {
        x: width / 2 - logoDims.width / 2,
        y: height - logoDims.height - 10,
        width: logoDims.width,
        height: logoDims.height,
    })
}

const drawFooter = (
    page: PDFPage,
    fonts: fontObject,
    pageNo: number,
    totalPages: number,
    logo?: PDFImage,
    watermark?: PDFImage
) => {
    const { width, height } = page.getSize()
    const pageNoDisplay = String(pageNo) + '/' + String(totalPages)
    //page no
    page.drawText(pageNoDisplay, {
        x:
            width / 2 -
            fonts.helveticaBold.widthOfTextAtSize(
                pageNoDisplay,
                FontSizes.pageNo
            ) /
                2,
        y: 33,
        size: FontSizes.pageNo,
        font: fonts.helveticaBold,
        color: rgb(0, 0, 0),
    })

    //logo and watermark printed for non-premium users
    if (logo && watermark) {
        const logoDims = logo.scale(0.2)
        page.drawImage(logo, {
            x: width - logoDims.width - 20,
            y: logoDims.height + 10,
            width: logoDims.width,
            height: logoDims.height,
        })

        const watermarkDims = watermark.scale(1.15)
        page.drawImage(watermark, {
            x: -90,
            y: -60,
            width: watermarkDims.width,
            height: watermarkDims.height,
        })
    }
}

const createIntroPage = (
    pdfDoc: PDFDocument,
    fonts: fontObject,
    logo: PDFImage,
    watermark: PDFImage,
    objective: string,
    isPremium: boolean,
    pageNo: number,
    totalPages: number,
    clientLogo?: PDFImage
) => {
    const page = pdfDoc.addPage()
    const { width, height } = page.getSize()

    clientLogo ? drawHeader(page, clientLogo) : null

    isPremium
        ? drawFooter(page, fonts, pageNo, totalPages)
        : drawFooter(page, fonts, pageNo, totalPages, logo, watermark)

    let strArray = []
    strArray = objective.split(/\r?\n/)

    page.drawText('Introduction and Objectives', {
        x: 30,
        y: height - 5 * FontSizes.title,
        size: FontSizes.title,
        maxWidth: width - 50,
        font: fonts.helveticaBold,
        color: rgb(0, 0, 0),
    })

    let yCoord = height - 10 * FontSizes.text
    for (let i = 0; i < strArray.length; i++) {
        if (strArray[i]) {
            const yDiff =
                i > 0
                    ? Math.round(
                          fonts.helvetica.widthOfTextAtSize(
                              strArray[i - 2],
                              FontSizes.text
                          ) /
                              (width - 60)
                      ) *
                          FontSizes.text +
                      20
                    : 0
            yCoord -= yDiff
            page.drawText(strArray[i], {
                x: 30,
                y: yCoord,
                size: FontSizes.text,
                lineHeight: 10,
                maxWidth: page.getWidth() - 50,
                font: fonts.helvetica,
                color: rgb(0, 0, 0),
            })
        }
    }
}

const createCoverPage = (
    pdfDoc: PDFDocument,
    fonts: fontObject,
    clientName: string
) => {
    const page = pdfDoc.addPage()
    const { width, height } = page.getSize()

    page.drawText(clientName, {
        x:
            width / 2 -
            fonts.helveticaBold.widthOfTextAtSize(
                clientName,
                FontSizes.coverTitle
            ) /
                2,
        y: height / 2,
        size: FontSizes.coverTitle,
        font: fonts.helveticaBold,
        color: rgb(0, 0, 0),
    })
}
