import React, { useState, useRef } from 'react'
import LayersIcon from '../../assets/icons/layers_black_24dp.svg'
import GlobeIcon from '../../assets/icons/public_black_24dp.svg'
import TerrainIcon from '../../assets/icons/terrain_black_24dp.svg'
import GrainIcon from '../../assets/icons/grain_black_24dp.svg'
import AngleIcon from '../../assets/icons/angle_icon_black.svg'
import SquareButton from '../elements/buttons/SquareButton'
import Dropdown from '../elements/Dropdown'
import IconButton from '../elements/buttons/IconButton'
import TextButton from '../elements/buttons/TextButton'
import TextInput from '../elements/inputs/TextInput'
import { WBlock } from '../elements/dividers'
import { AngleRange } from '../../types'
import Slider from '../elements/Slider'
import { useMsal } from '@azure/msal-react'
import { authorisedUsersToEditOffsets } from '../../constants/environment'

export type MapToolbarPanelProps = {
    showTrail?: boolean
    showSonar?: boolean
    showMap?: boolean
    showModels?: boolean
    showAngles?: boolean
    asset?: string
    assetList?: string[]
    modelsOffset?: number[]
    materialSize?: number
    originalMaterialSize?: number
    modelFile?: File | null
    kmlFile?: File | null
    mosaicFiles?: File[]
    angleRange?: AngleRange
    markersModelsHaveAnyOffsets?: boolean
    setShowTrail?: (value: boolean) => void
    setShowSonar?: (value: boolean) => void
    setShowMap?: (value: boolean) => void
    setShowModels?: (value: boolean) => void
    setShowAngles?: (value: boolean) => void
    setLocation?: (value: string) => void
    setClearTrail?: () => void
    setAsset?: (value: string) => void
    setModelsOffset?: (value: number[]) => void
    setMaterialSize?: (value: number) => void
    setAngleRange?: (value: AngleRange) => void
    saveNewSettings?: (value: number[]) => void
    setModelFile?: (file: File | null) => void
    setKMLFile?: (file: File | null) => void
    setMosaicFiles?: (file: File[]) => void
    setDefaultSettings?: () => void
}

const MapToolbarPanel = (props: MapToolbarPanelProps): JSX.Element => {
    const {
        showMap,
        setShowMap,
        showModels,
        setShowModels,
        showAngles,
        setShowAngles,
        setClearTrail,
        asset,
        assetList,
        setAsset,
        modelsOffset,
        setModelsOffset,
        materialSize,
        originalMaterialSize,
        setMaterialSize,
        saveNewSettings,
        modelFile,
        setModelFile,
        kmlFile,
        setKMLFile,
        mosaicFiles,
        setMosaicFiles,
        angleRange,
        setAngleRange,
        setDefaultSettings,
    } = props

    const { accounts } = useMsal()
    const allowingToEditOffsets = authorisedUsersToEditOffsets.includes(
        accounts[0].username
    )

    const fileInputRef = useRef<HTMLInputElement>(null)
    const mosaicFilesInputRef = useRef<HTMLInputElement>(null)
    const [showPanel, setShowPanel] = useState(false)

    const updateModelsOffset = (value: string, idx: number) => {
        if (Number.isNaN(value) || !modelsOffset || !setModelsOffset) return
        const array = [...modelsOffset]
        array[idx] = Number(value)
        setModelsOffset(array)
    }

    const updateMaterialSize = (value: number) => {
        if (!setMaterialSize) return
        setMaterialSize(value)
    }

    const handleSelectFile = (e: any) => {
        if (!e.target) return
        if (!e.target.files || e.target.files.length === 0) {
            alert('Please choose a file.')
            return
        } else if (e.target.files.length > 1) {
            alert('Please only choose 1 file.')
            return
        } else {
            setModelFile && setModelFile(e.target.files[0])
        }
    }

    const handleSelectKML = (e: any) => {
        if (!e.target) return
        if (!e.target.files || e.target.files.length === 0) {
            alert('Please choose a file.')
            return
        } else if (e.target.files.length > 1) {
            alert('Please only choose 1 file.')
            return
        } else {
            console.log(e.target.files[0])
            setKMLFile && setKMLFile(e.target.files[0])
        }
    }

    const handleSelectMosaic = (e: any) => {
        if (!e.target) return
        if (!e.target.files || e.target.files.length === 0) {
            alert('Please choose a file.')
            return
        } else {
            setMosaicFiles && setMosaicFiles(e.target.files)
        }
    }

    const offsetsNotModified =
        modelsOffset && modelsOffset.every((value) => value === 0)
    const materialSizeNotModified = materialSize === originalMaterialSize

    return (
        <div className={``}>
            <div
                className={`${showPanel ? '' : 'hide'}`}
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                }}
                onClick={() => setShowPanel(!showPanel)}>
                <div
                    className={`map-toolbar-panel surface-bg on-surface shadow w-fit-content`}
                    onClick={(e) => e.stopPropagation()}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        <div
                            className='title'
                            style={{ flex: '1', marginBottom: '8px' }}>
                            Map Details
                        </div>
                        <div style={{ flex: '0 0 80px', marginTop: '-12px' }}>
                            {setClearTrail && (
                                <TextButton
                                    className={`shadow`}
                                    text='Clear Trail'
                                    onClick={() => setClearTrail()}
                                />
                            )}
                        </div>
                    </div>
                    <div className='break-row' />
                    {showMap !== undefined && setShowMap && (
                        <SquareButton
                            text='Satellite Map'
                            Icon={GlobeIcon}
                            selected={showMap}
                            onClick={() => setShowMap(!showMap)}
                        />
                    )}
                    {showModels !== undefined && setShowModels && (
                        <SquareButton
                            text='3D Terrain'
                            Icon={TerrainIcon}
                            selected={showModels}
                            onClick={() => setShowModels(!showModels)}
                        />
                    )}
                    {setAngleRange && setShowAngles && (
                        <SquareButton
                            text='Marker Angles'
                            Icon={AngleIcon}
                            selected={showAngles}
                            onClick={() => setShowAngles(!showAngles)}
                        />
                    )}
                    {setModelFile && (
                        <>
                            <SquareButton
                                text='XYZ Model'
                                Icon={GrainIcon}
                                selected={modelFile !== null}
                                onClick={() =>
                                    modelFile
                                        ? setModelFile(null)
                                        : fileInputRef.current?.click()
                                }
                            />
                            <input
                                ref={fileInputRef}
                                type='file'
                                name='name'
                                style={{ display: 'none' }}
                                onChange={handleSelectFile}
                            />
                        </>
                    )}
                    {setKMLFile && (
                        <>
                            <SquareButton
                                text='Load kml'
                                Icon={GrainIcon}
                                selected={kmlFile !== null}
                                onClick={() =>
                                    kmlFile
                                        ? setKMLFile(null)
                                        : fileInputRef.current?.click()
                                }
                            />
                            <input
                                ref={fileInputRef}
                                type='file'
                                name='name'
                                style={{ display: 'none' }}
                                accept='.kml'
                                onChange={handleSelectKML}
                            />
                        </>
                    )}
                    {setMosaicFiles && (
                        <>
                            <SquareButton
                                text='Load Mosaic'
                                Icon={GrainIcon}
                                selected={mosaicFiles && mosaicFiles.length > 0}
                                onClick={() =>
                                    mosaicFiles && mosaicFiles.length > 0
                                        ? setMosaicFiles([])
                                        : mosaicFilesInputRef.current?.click()
                                }
                            />
                            <input
                                ref={mosaicFilesInputRef}
                                multiple
                                type='file'
                                name='name'
                                style={{ display: 'none' }}
                                accept='.png'
                                onChange={handleSelectMosaic}
                            />
                        </>
                    )}
                    {setAngleRange && angleRange && showAngles && (
                        <div className='flex align-center justify-around padding-b-md'>
                            <TextInput
                                value={String(angleRange.min)}
                                onChange={(value) =>
                                    setAngleRange({
                                        min: Number(value),
                                        max: angleRange.max,
                                    })
                                }
                                error={'Only numbers allowed'}
                                title={'Min Angle'}
                                regex={/^-?[0-9]*(\.\d+)?$/gm}
                            />
                            <WBlock />
                            <TextInput
                                value={String(angleRange.max)}
                                onChange={(value) =>
                                    setAngleRange({
                                        min: angleRange.min,
                                        max: Number(value),
                                    })
                                }
                                error={'Only numbers allowed'}
                                title={'Max Angle'}
                                regex={/^-?[0-9]*(\.\d+)?$/gm}
                            />
                        </div>
                    )}
                    {materialSize !== undefined && (
                        <>
                            <div className='flex align-center justify-start padding-md'>
                                <div>
                                    <div className='text-md text-medium padding-sm'>
                                        Point Cloud Size:
                                    </div>
                                    <div className={`text-sm`}>
                                        (original: {originalMaterialSize})
                                    </div>
                                </div>
                                <Slider
                                    value={materialSize * 10}
                                    onChange={(value) =>
                                        updateMaterialSize(
                                            Number((value / 10).toFixed(2))
                                        )
                                    }
                                    min={0.1}
                                    max={10}
                                    step={0.1}
                                />
                                {/* <TextInput
                                value={String(materialSize)}
                                onChange={(value) => updateMaterialSize(String(value))}
                                className={`w-70`}
                                error={'Only numbers allowed'}
                                title={'Material Size'}
                                regex={/^-?[0-9]*(\.\d+)?$/gm}
                            /> */}
                            </div>
                        </>
                    )}
                    {modelsOffset !== undefined && allowingToEditOffsets && (
                        <>
                            <div className={`text-sm top-4px rel margin-t-sm`}>
                                * The direction of mcd is opposite to z-axis
                            </div>
                            <div className='flex align-center justify-center'>
                                <TextInput
                                    value={String(modelsOffset[0])}
                                    onChange={(value) =>
                                        updateModelsOffset(String(value), 0)
                                    }
                                    error={'Only numbers allowed'}
                                    title={'X Offset'}
                                    regex={/^-?[0-9]*(\.\d+)?$/gm}
                                />
                                <WBlock />
                                <TextInput
                                    value={String(modelsOffset[1])}
                                    onChange={(value) =>
                                        updateModelsOffset(String(value), 1)
                                    }
                                    error={'Only numbers allowed'}
                                    title={'Y Offset'}
                                    regex={/^-?[0-9]*(\.\d+)?$/gm}
                                />
                                <WBlock />
                                <TextInput
                                    value={String(modelsOffset[2])}
                                    onChange={(value) =>
                                        updateModelsOffset(String(value), 2)
                                    }
                                    error={'Only numbers allowed'}
                                    title={'MCD'}
                                    regex={/^-?[0-9]*(\.\d+)?$/gm}
                                />
                            </div>
                        </>
                    )}
                    {allowingToEditOffsets && (
                        <div className='flex align-center'>
                            {setDefaultSettings && (
                                <TextButton
                                    onClick={() => {
                                        setDefaultSettings()
                                    }}
                                    text='Remove offsets'
                                    disabled={
                                        props.markersModelsHaveAnyOffsets ===
                                        undefined
                                            ? false
                                            : !props.markersModelsHaveAnyOffsets
                                    }
                                />
                            )}
                            {modelsOffset !== undefined && saveNewSettings && (
                                <TextButton
                                    onClick={() => {
                                        saveNewSettings(modelsOffset)
                                        setModelsOffset &&
                                            setModelsOffset([0, 0, 0])
                                        setShowPanel(false)
                                    }}
                                    text='Save Settings'
                                    disabled={
                                        materialSize !== undefined
                                            ? materialSizeNotModified &&
                                              offsetsNotModified
                                            : offsetsNotModified
                                    }
                                />
                            )}
                        </div>
                    )}
                    {/*
          <SquareButton
            title='Trail'
            isClicked={showTrail}
            Icon={TimelineIcon}
            onClick={() => setShowTrail(!showTrail)}
          /> */}
                    {/* <Button
            className={`shadow ${showDistance ? 'primary' : ''}`}
            title='Show distance'
            Icon={SquareFootIcon}
            onClick={() => dispatch(toggleShowDistance())}
          /> */}
                    {/* <SquareButton
            title='Boat Distance'
            isClicked={showDistance}
            Icon={TimelineIcon}
            onClick={() => setShowTrail(!showTrail)}
          /> */}
                    {/* <SquareButton
            title='Sonar'
            isClicked={showSonar}
            Icon={SonarIcon}
            onClick={() => setShowSonar(!showSonar)}
          />*/}
                    {asset !== undefined &&
                        assetList !== undefined &&
                        setAsset && (
                            <>
                                <div className='break-row' />
                                <div
                                    className='title'
                                    style={{ flex: '1', marginBottom: '8px' }}>
                                    Map Location
                                </div>
                                <div className='break-row' />
                                <div
                                    style={{
                                        width: '100%',
                                        height: '40px',
                                        marginBottom: '8px',
                                    }}>
                                    <Dropdown
                                        list={assetList}
                                        selected={asset}
                                        select={(value) => setAsset(value)}
                                    />
                                </div>
                            </>
                        )}
                </div>
            </div>
            <IconButton
                Icon={LayersIcon}
                selected={showPanel}
                containerColor='background'
                onClick={() => {
                    setShowPanel(!showPanel)
                }}
            />
        </div>
    )
}

export default MapToolbarPanel
